




















@import '~@/assets/styles/helpers/_variables.scss';

.v-divider {
  background: $colorPrimary;
}
